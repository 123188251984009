

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Loader from '../loader/Loader';
import { Button } from 'reactstrap';
import { FaEye } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddDocuments from './AddDocuments';
const BasicInformationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    // middleName: '',
    // lastName: '',
    mobileNumber: '',
    alternatemobile: '',
    email: '',
    dob: '',
    gender: '',
    occupation: '',
    addressLine1: '',
    city: '',
    pincode: '',
    taluka: '',
    district: '',
    state: '',
    country: '',
    dob: '',
    occupation: '',

  });
  const [photoPreview, setPhotoPreview] = useState(''); // New state for image preview
  const [photo, setPhoto] = useState(null); // New state for the selected file
  const [studentId, setStudentId] = useState(''); // State to store student ID
  const [showIdentityDocuments, setShowIdentityDocuments] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
const [loading, setLoading] = useState(false); // Loading state

  const [identityImagePreview, setIdentityImagePreview] = useState(null);
  const [showCredentials, setShowCredentials] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confPassword: ''
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL


  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/myprofile`);
        const data = response.data;
        setFormData(data);
        console.log(data);
        
        setPhotoPreview(`${apiUrl}/${data.photo}`); // Set the existing photo as preview
        setStudentId(data.studentId); // Set the student ID
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
      finally{
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData(prevData => ({
      ...prevData,
      [name]: ["fir stName",'name', "middleName", "lastName"].includes(name)
        ? value.toUpperCase() // Convert to uppercase for specific fields
        : value, // Leave other fields unchanged
    }));
  };
  
  const [msg, setMsg] = useState('');

  const handleTabChange1 = (tab) => {
    setShowProfile(tab === 'profile');
    setShowIdentityDocuments(tab === 'identityDocuments');
    setShowCredentials(tab === 'credentials');

  };
  
  const handleTabChange2 = (tab) => {
    setShowIdentityDocuments(tab === 'identityDocuments');

    setShowProfile(tab === 'profile');
    setShowCredentials(tab === 'credentials');

    
  };

  const handleTabChange3 = (tab) => {
    setShowCredentials(tab === 'credentials');
    setShowProfile(tab === 'profile');
    setShowIdentityDocuments(tab === 'identityDocuments');
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handlePasswordSubmit = async () => {
    try {
      await axios.patch(`${apiUrl}/updatepassword`, passwordData);
      toast.success('Password updated successfully!', { position: toast.POSITION.TOP_RIGHT });
    } catch (error) {
      // toast.error('Error updating password.',error, { position: toast.POSITION.TOP_RIGHT });
      // console.error('Error updating password:', error);
      if (error.response) {
        setMsg(error.response.data.msg);
        console.log(error.response.data.msg);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${error.response.data.msg}`,
      });
        
    }
    }
  };
  
  
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, photo: file }));
    const reader = new FileReader();
    reader.onload = () => {
      setPhotoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleIdentityImageChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, identityImage: file }));
    const reader = new FileReader();
    reader.onload = () => {
      setIdentityImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file)); // Preview the selected image
    }
  };
  const downloadImage = async (url, filename) => {
    try {
      const response = await fetch(url, {
        credentials: 'include' // Include cookies in the request
        // You may also need to include additional headers like Authorization if using JWT tokens
      });
      if (!response.ok) {
        throw new Error(`Failed to download image: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // Handle the error gracefully, e.g., display an error message to the user
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Update basic profile data
      await axios.patch(`${apiUrl}/updateprofile`, formData);

      // Upload photo if a new one has been selected
      if (photo) {
        const formDataToUpload = new FormData();
        formDataToUpload.append('image', photo);
        await axios.patch(`${apiUrl}/updateprofile`, formDataToUpload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      toast.success('Profile updated successfully!', { position: toast.POSITION.TOP_RIGHT });    } catch (error) {
      console.error('Error updating profile:', error);
    }
    finally{
      setLoading(false);
    }

  };

  if (loading) {
    return <Loader />;
  }
  
  return (
    <div className='container'>
          <ToastContainer />

      <div className="col-md-7 col-lg-8 col-xl-9" style={{ width: '100%' }}>
        <div className="card" style={{ width: 'inherit' }}>
          <div className="card-body">
            <h4 className="card-title">Basic Information</h4>
            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded" style={{width:'fit-content'}}>
  <li className="nav-item">
    <a 
      className={`nav-link ${showProfile ? 'active' : ''}`} 
      onClick={() => handleTabChange2('profile')}
      data-bs-toggle="tab"
    >
      Profile
    </a>
  </li>
  {/* <li className="nav-item">
    <a 
      className={`nav-link ${showIdentityDocuments ? 'active' : ''}`} 
      onClick={() => handleTabChange1('identityDocuments')}
      data-bs-toggle="tab"
    >
      Identity Documents
    </a>
  </li> */}
  <li className="nav-item">
  <a 
    className={`nav-link ${showCredentials ? 'active' : ''}`} 
    onClick={() => handleTabChange3('credentials')}
    data-bs-toggle="tab"
  >
    Credentials
  </a>
</li>

            </ul>

                        <br /><br />
            <form onSubmit={handleSubmit}>
            {showProfile && (

              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <div className="change-avatar">
                      <div className="profile-img">
                        <img src={photoPreview} alt="User Image" />
                      </div>
                      <div className="upload-img">
                        <div className="change-photo-btn">
                        <div style={{width:"150px"}}><FontAwesomeIcon icon={faUpload} /> Upload Photo</div>
                          <input type="file" className="upload" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                        </div>
                        <small className="form-text text-muted">Allowed JPG, PNG and JPEG. Max size of 2MB</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Name <span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} />
                  </div>
                </div>
                {/* <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Middle Name <span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="middleName" value={formData.middleName} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Last Name <span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleChange} />
                  </div>
                </div> */}
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Mobile No. <span className="text-danger"> *</span></label>
                    <input type="text"  className="form-control" name="mobile" value={formData.mobileNumber} onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Alternate Mobile No.</label>
                    <input type="text" className="form-control" name="alternatemobile" value={formData.alternatemobile} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Email ID <span className="text-danger"> *</span></label>
                    <input type="email" readOnly className="form-control" name="email" value={formData.email} />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Date of Birth <span className="text-danger"> *</span></label>
                    <input type="date" className="form-control datetimepicker" name="dob" value={formData.dob} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Gender <span className="text-danger"> *</span></label>
                    <select className="form-select form-control" name="gender" value={formData.gender} onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <label className="mb-2">Occupation <span className="text-danger"> *</span></label>
                    <input type="text"  className="form-control" name="occupation" value={formData.occupation} onChange={handleChange}/>
                  </div>
                </div>
                    <div className="col-12 row-md-4">
                      <div className="mb-3">
                        <label className="mb-2">Address Line 1 <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="addressLine1" value={formData.addressLine1} onChange={handleChange} />
                      </div>
                      <div className="mb-3">
                        <label className="mb-2">Address Line 2 <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 row-md-4">
                      <div className="mb-3">
                        <label className="mb-2">City <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <label className="mb-2">Pincode <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="pincode" value={formData.pincode} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <label className="mb-2">Taluka <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="taluka" value={formData.taluka} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <label className="mb-2">District <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="district" value={formData.district} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <label className="mb-2">State <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="state" value={formData.state} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <label className="mb-2">Country <span className="text-danger"> *</span></label>
                        <input type="text" className="form-control" name="country" value={formData.country} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
            )}
              {/* {showIdentityDocuments && (
                <div>
                  <div className="form-group-row">
                    <div className="form-column">
                      <label htmlFor="identityType">Identity Type:</label>
                      <select id="identityType" name="identityType" value={formData.identityType} onChange={handleChange}>
                        <option value="Driving License">Driving License</option>
                        <option value="Aadhaar Card">Aadhaar Card</option>
                        <option value="Pan Card">Pan Card</option>
                        <option value="Voter ID">Voter ID</option>
                        <option value="College ID">College ID</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="form-column">
                      <label htmlFor="identityNo">Identity Number:</label>
                      <input type="text" id="identityNo" name="identityNo" placeholder="Enter Identity Number" required value={formData.identityNo} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="form-group-row">
                    <div className="form-column">
                      <label htmlFor="photo">Student Photo:</label>
                      <input type="file" id="photo" name="photo" accept="image/*" onChange={handlePhotoChange} />
                      {formData.photo && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Button color="info" onClick={() => photoPreview(!setPhotoPreview)}>
                              <FaEye /> View
                            </Button>
                            <Button color="danger" onClick={() => downloadImage(photoPreview, 'photo.png')}>
                              Download
                            </Button>
                          </div>
                          {setPhotoPreview && (
                            <img
                              src={photoPreview}
                              alt="Student"
                              style={{
                                width: '200px',
                                height: '200px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                marginTop: '10px'
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="form-column">
                      <label htmlFor="identityImage">Identity Image:</label>
                      <input type="file" id="identityImage" name="identityImage" accept="image/*" onChange={handleIdentityImageChange} />
                      {formData.identityImage && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Button color="info" onClick={() => identityImagePreview(!setIdentityImagePreview)}>
                              <FaEye /> View
                            </Button>
                            <Button color="danger" onClick={() => downloadImage(identityImagePreview, 'identity.png')}>
                              Download
                            </Button>
                          </div>
                          {setIdentityImagePreview && (
                            <img
                              src={identityImagePreview}
                              alt="Identity"
                              style={{
                                width: '350px',
                                height: '200px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                marginTop: '10px'
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  
                </div>
              )} */}
             {showCredentials && (
  <div className="row">
    <div className="col-12 col-md-4">
      <div className="mb-3">
        <label className="mb-2">
          Old Password <span style={{ color: 'red' }}>*</span>
        </label>
        <div className="input-group">
          <input
            type={showOldPassword ? "text" : "password"}
            className="form-control"
            name="oldPassword"
            value={passwordData.oldPassword}
            onChange={handlePasswordChange}
            required
            style={{marginTop:"0px"}}
          />
          <span
            className="input-group-text"
            onClick={() => setShowOldPassword(!showOldPassword)}
            style={{ cursor: 'pointer', marginBottom:"0.85rem" }}
          >
            {showOldPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
      </div>
    </div>

    <div className="col-12 col-md-4">
      <div className="mb-3">
        <label className="mb-2">
          New Password <span style={{ color: 'red' }}>*</span>
        </label>
        <div className="input-group">
          <input
            type={showNewPassword ? "text" : "password"}
            className="form-control"
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handlePasswordChange}
            required
            style={{marginTop:"0px"}}
          />
          <span
            className="input-group-text"
            onClick={() => setShowNewPassword(!showNewPassword)}
            style={{ cursor: 'pointer', marginBottom:"0.85rem" }}
          >
            {showNewPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
      </div>
    </div>

    <div className="col-12 col-md-4">
      <div className="mb-3">
        <label className="mb-2">
          Confirm Password <span style={{ color: 'red' }}>*</span>
        </label>
        <div className="input-group">
          <input
            type={showConfPassword ? "text" : "password"}
            className="form-control"
            name="confPassword"
            value={passwordData.confPassword}
            onChange={handlePasswordChange}
            required
            style={{marginTop:"0px"}}
          />
          <span
            className="input-group-text"
            onClick={() => setShowConfPassword(!showConfPassword)}
            style={{ cursor: 'pointer', marginBottom:"0.85rem" }}
          >
            {showConfPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
      </div>
    </div>

    <div className="col-12">
      <button onClick={handlePasswordSubmit}>
        Update Password
      </button>
    </div>
  </div>
)}

{!showCredentials && (
              <div className="submit-section">
                <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
              </div>)}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformationForm;
